<template>
  <div class="secondary--text">
    <div class="">
      <v-row justify="space-between" align="end" no-gutters>
        <div class="text-body-1 text-md-h5 text-h6">
          <b class="text-uppercase">
            {{ title }}
          </b>
        </div>
        <router-link
          class="text-small text-sm-body-2 text-md-body-1 secondary--text"
          :to="{ name: redirectList }"
        >
          <b class="">{{ textMoreInfo }}</b>
        </router-link>
      </v-row>
      <p class="py-2 py-md-3 text-small text-sm-body-2 text-md-body-1">
        {{ subTitle }}
      </p>
    </div>
    <v-row class="" align="start" justify="start">
      <v-col
        v-for="(i, index) in recipesObj"
        :key="index"
        :cols="isMobile ? '6' : '3'"
        class=""
      >
        <router-link
          :to="{
            name: getRecipeDetailsRouteName(i.category),
            params: { id: i.id }
          }"
        >
          <img :src="i.thumbnail" :alt="i.name" width="100%" />
          <div class="pb-2 font-weight-bold text-md-h6 text-h7">
            {{ i.name }}
          </div>
          <p class="ma-0 text-small text-sm-body-2 text-md-body-1">
            {{ i.price }}
          </p>
          <p class="ma-0 text-small text-sm-body-2 text-md-body-1">
            {{ i.creatorName }}
          </p>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import intersectMixin from '@/core/mixin/intersect.mixin';
  import commonMixin from '@/core/mixin/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { RecipeType } from '@/core/constants/enums';

  export default {
    name: 'RecipeBasicList',
    mixins: [intersectMixin, commonMixin],
    props: {
      type: {
        type: Number,
        default: null
      },
      title: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        default: ''
      },
      textMoreInfo: {
        type: String,
        default: ''
      },
      redirectList: {
        type: String,
        default: ''
      },
      recipesObj: {
        type: Array,
        default: () => {}
      }
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    created() {
      this.onIntersectActionEnterXOnce();
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    watch: {},
    methods: {
      getRecipeDetailsRouteName(type) {
        let routeName = {
          [RecipeType.BAKERY]: ROUTE_NAME.RECIPE_BAKERY_DETAILS,
          [RecipeType.ASIAN]: ROUTE_NAME.RECIPE_ASIAN_DETAILS,
          [RecipeType.WESTERN]: ROUTE_NAME.RECIPE_WESTERN_DETAILS
        };
        return routeName[type];
      }
    }
  };
</script>

<style></style>
