<template>
  <RecipeDetails></RecipeDetails>
</template>

<script>
  import RecipeDetails from '@/views/components/recipe/RecipeDetails.vue';

  export default {
    name: 'RecipeBakeryDetails',
    components: {
      RecipeDetails
    },
    created() {},
    conputer: {},
    watch: {}
  };
</script>

<style></style>
