<template>
  <div class="recipe">
    <!-- recipe details & QRcode ::start -->
    <div
      class="content-wrapper pt-md-6 pb-md-12 secondary--text slide-fade-x-enter enter-delay-150ms"
    >
      <div class="pt-5">
        <div
          class="pb-md-5 pb-3 text-body-1 text-md-h5 text-h6 font-weight-bold"
        >
          {{ recipe.name }}
        </div>
        <div v-if="isMobile" class="pb-2">
          <div>
            <div class="text-md-h6 text-h7 font-weight-bold">
              {{
                `${recipe.ingredient} ${$t('label.ingredients')}  . 
           ${recipe.servePax} ${$t('label.servings')}  . 
           ${recipe.price} `
              }}
            </div>
          </div>
          <div class="text-small text-sm-body-2 text-md-body-1">
            {{ recipe.description }}
          </div>
        </div>
        <v-row
          justify="space-between"
          align="center"
          :class="[{ 'flex-column': isMobile }]"
        >
          <v-col class="text-center" cols="12" md="6" lg="6">
            <video
              v-if="['mp4', 'MP4', 'mov', 'MOV'].includes(recipe.assetType)"
              :src="recipe.asset"
              :width="'100%'"
              class="text-left"
              autoplay
              loop
              muted
              playsinline
              controls
            ></video>
            <img v-else :width="'100%'" :src="recipe.asset" />
          </v-col>
          <v-col cols="9" md="4" lg="4">
            <div
              class="pa-6 rounded-xl primary text-center slide-fade-y-enter enter-delay-100ms"
              :class="[{ 'w-100': isMobile }]"
            >
              <qr-code
                :text="mobileLink.shortLink"
                :size="qrCodeSize"
                class="d-flex justify-center"
                id="smallqrcode"
              ></qr-code>
              <div class="pt-3 text-small text-sm-body-2 text-md-body-1">
                Scan to watch video & buy Eco Pack now!
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="!isMobile">
        <div>
          <div class="py-4 text-md-h6 text-h7 font-weight-bold">
            {{
              `${recipe.ingredient} ${$t('label.ingredients')}  . 
           ${recipe.servePax} ${$t('label.servings')}  . 
           ${recipe.price} `
            }}
          </div>
        </div>
        <div class="text-small text-sm-body-2 text-md-body-1">
          {{ recipe.description }}
        </div>
      </div>
    </div>
    <!-- recipe details & QRcode ::end -->
    <!-- recipe catagory ::start -->
    <div class="slide-fade-x-enter enter-delay-50ms">
      <div
        v-for="(i, index) in recipeType.filter(
          (x) => x.type == recipe.category
        )"
        :key="index + 10"
        class="pt-5 secondary--text"
        :class="[{ lightBlue: recipe.category != i.type }]"
      >
        <v-card
          class="content-wrapper pb-7"
          :class="[{ lightBlue: recipe.category != i.type }]"
          flat
        >
          <RecipeBasicList
            :type="i.type"
            :title="i.title"
            :subTitle="i.subTitle"
            :textMoreInfo="i.textMoreInfo"
            :redirectList="i.redirectList"
            :recipesObj="i.recipes"
            class=""
          ></RecipeBasicList>
        </v-card>
      </div>
      <div
        v-for="(i, index) in recipeType.filter(
          (x) => x.type != recipe.category
        )"
        :key="index + 20"
        class="pt-5 secondary--text"
        :class="[{ lightBlue: recipe.category != i.type }]"
      >
        <v-card
          class="content-wrapper pb-7"
          :class="[{ lightBlue: recipe.category != i.type }]"
          flat
        >
          <RecipeBasicList
            :type="i.type"
            :title="i.title"
            :subTitle="i.subTitle"
            :textMoreInfo="i.textMoreInfo"
            :redirectList="i.redirectList"
            :recipesObj="i.recipes"
            class=""
          ></RecipeBasicList>
        </v-card>
      </div>
    </div>
    <!-- recipe catagory ::end -->
  </div>
</template>
<style>
  #smallqrcode img {
    border: 10px solid #ffffff;
  }
</style>
<script>
  import RecipeBasicList from '@/views/components/recipe/RecipeBasicList';
  import commonMixin from '@/core/mixin/common.mixin';
  import intersectMixin from '@/core/mixin/intersect.mixin';
  import { RecipeType } from '@/core/constants/enums';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    RECIPE_GET_RECIPES,
    RECIPE_GET_RECIPE,
    RECIPE_GET_RECIPE_MOBILE_LINK,
    RECIPE_INITIAL_GET_RECIPES_STATE,
    RECIPE_INITIAL_GET_RECIPE_STATE,
    RECIPE_INITIAL_GET_RECIPE_MOBILE_LINK_STATE
  } from '@/core/store/recipe.module';

  export default {
    name: 'RecipeDetails',
    mixins: [commonMixin, intersectMixin],
    components: {
      RecipeBasicList
    },
    data: () => ({
      recipeType: [],
      recipesBakery: [],
      recipesAsian: [],
      recipesWestern: [],
      recipe: {},
      mobileLink: {
        shortLink: ''
      }
    }),
    created() {
      this.initailRecipeDetails();
    },
    mounted() {
      this.onIntersectActionEnterXOnce();
      this.onIntersectActionEnterYOnce();
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile;
      },
      qrCodeSize() {
        let size = 140;

        if (this.$vuetify.breakpoint.lgAndUp) {
          size = 200;
        } else if (this.$vuetify.breakpoint.mdOnly) {
          size = 180;
        } else if (this.$vuetify.breakpoint.smAndDown) {
          size = 160;
        }

        return size;
      },
      id() {
        return this.$route.params.id;
        // return 55;
      },
      recipesComplete() {
        return this.$store.state.recipe.recipes.complete;
      },
      recipeComplete() {
        return this.$store.state.recipe.recipe.complete;
      },
      recipeMobileLinkComplete() {
        return this.$store.state.recipe.recipeMobileLink.complete;
      }
    },
    watch: {
      recipesComplete() {
        let response = this.$store.state.recipe.recipes;
        if (response.complete) {
          let recipes = [];
          recipes.push(response.data);
          if (
            recipes.find((x) => x.find((i) => i.category == RecipeType.BAKERY))
          ) {
            this.recipesBakery = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.BAKERY)
            );
          } else if (
            recipes.find((x) => x.find((i) => i.category == RecipeType.ASIAN))
          ) {
            this.recipesAsian = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.ASIAN)
            );
          } else {
            this.recipesWestern = recipes.find((x) =>
              x.find((i) => i.category == RecipeType.WESTERN)
            );
          }
          this.recipesCompleteAction(response, 'getRecipes');
        }
      },
      recipeComplete() {
        let response = this.$store.state.recipe.recipe;
        if (response.complete) {
          this.recipe = response.data;
          this.recipesCompleteAction(response, 'getRecipe');
        }
      },
      recipeMobileLinkComplete() {
        let response = this.$store.state.recipe.recipeMobileLink;
        if (response.complete) {
          this.mobileLink = response.data;
          this.recipesCompleteAction(response, 'getMobileLink');
        }
      }
    },
    methods: {
      recipesCompleteAction(response, actionType) {
        let type = null;
        let buttons = [];
        let title = i18nHelper.getMessage('label.recipes');
        let message = response.message;
        buttons = [
          {
            color: 'secondary',
            text: i18nHelper.getMessage('label.ok'),
            action: () => {
              this.closeAppDialogInfo();
            }
          }
        ];
        if (response.code == 0) {
          switch (actionType) {
            case 'getRecipes':
              this.initialGetRecipesState();
              this.initailRecipe();
              break;
            case 'getRecipe':
              this.initialGetRecipeState();
              this.getRecipeMobileLink(this.recipe.recipeId);
              break;
            case 'getMobileLink':
              this.initialGetRecipeMobileLinkState();
              break;
          }
          type = 'success';
        } else {
          type = 'error';
        }
        if (message) {
          this.openAppDialogInfo(type, title, message, buttons);
        }
      },
      async initailRecipeDetails() {
        this.getRecipe(this.id);
        this.getRecipes(RecipeType.BAKERY);
        this.getRecipes(RecipeType.ASIAN);
        this.getRecipes(RecipeType.WESTERN);
        this.initailRecipe();
      },
      initailRecipe() {
        this.recipeType = [
          {
            type: RecipeType.BAKERY,
            title: i18nHelper.getMessage(
              `enumsRecipeType.${RecipeType.BAKERY}`
            ),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleBakery'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantBakeMore'),
            redirectList: ROUTE_NAME.RECIPE_BAKERY_LIST,
            recipes: this.recipesBakery
          },
          {
            type: RecipeType.ASIAN,
            title: i18nHelper.getMessage(`enumsRecipeType.${RecipeType.ASIAN}`),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleAsian'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantCookMore'),
            redirectList: ROUTE_NAME.RECIPE_ASIAN_LIST,
            recipes: this.recipesAsian
          },
          {
            type: RecipeType.WESTERN,
            title: i18nHelper.getMessage(
              `enumsRecipeType.${RecipeType.WESTERN}`
            ),
            subTitle: i18nHelper.getMessage('messageRecipe.subTitleWestern'),
            textMoreInfo: i18nHelper.getMessage('messageRecipe.iWantCookMore'),
            redirectList: ROUTE_NAME.RECIPE_WESTERN_LIST,
            recipes: this.recipesWestern
          }
        ];
      },
      getRecipes(type) {
        let data = {
          category: type,
          perPage: this.isMobile ? 2 : 4
        };
        this.$store.dispatch(RECIPE_GET_RECIPES, { data });
      },
      getRecipe(id) {
        this.$store.dispatch(RECIPE_GET_RECIPE, { id });
      },
      getRecipeMobileLink(id) {
        this.$store.dispatch(RECIPE_GET_RECIPE_MOBILE_LINK, { id });
      },
      initialGetRecipeState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_STATE);
      },
      initialGetRecipeMobileLinkState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPE_MOBILE_LINK_STATE);
      },
      initialGetRecipesState() {
        this.$store.dispatch(RECIPE_INITIAL_GET_RECIPES_STATE);
      }
    }
  };
</script>

<style></style>
